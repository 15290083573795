import axios from "axios";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { FaCheckCircle } from "react-icons/fa";

import Container from "../container";

const customStyles = {
  successColor: "#155724",
  successBg: "#d4edda",
  errorColor: "#721c24",
  errorBg: "#f5c6cb"
};

const ADD_NEW_CONTACT_ENDPOINT = "/.netlify/functions/brevo-add-new-contact";

export default class Subscribe extends React.Component {
  state = {
    formValues: { name: "", email: "" },
    sendStatus: { success: null },
    isSubmitting: false
  };

  handleSubmit = async (e) => {
    this.setState((prevState) => ({
      isSubmitting: !prevState.isSubmitting
    }));

    e.preventDefault();

    axios
      .post(ADD_NEW_CONTACT_ENDPOINT, this.getSubscribePayload())
      .then((_) => {
        this.resetForm();
        this.setState((prevState) => ({
          sendStatus: {
            ...prevState.sendStatus,
            success: true
          },
          isSubmitting: !prevState.isSubmitting
        }));
        if (this.props.toggleModal) {
          this.props.toggleModal();
        }
      })
      .catch((_) => {
        this.setState((prevState) => ({
          sendStatus: {
            ...prevState.sendStatus,
            success: false,
            isSubmitting: !prevState.isSubmitting
          }
        }));
      });
  };

  resetForm = () => {
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        name: "",
        email: ""
      }
    }));
  };

  handleInputChange = (event) => {
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        [event.target.name]: event.target.value
      }
    }));
  };

  getSubscribePayload = () => {
    return {
      email: this.state.formValues.email,
      attributes: {
        PRENOM: this.state.formValues.name
      },
      listIds: process.env.GATSBY_BREVO_LIST_VSTC_ID ? [+process.env.GATSBY_BREVO_LIST_VSTC_ID] : []
    };
  };

  render() {
    let { success } = this.state.sendStatus;
    let content = {
      title: "",
      message: ""
    };
    const renderMessage = () => {
      if (success != null) {
        if (success === true) {
          content.title = `Vous avez rejoint la communauté :)`;
          content.message = `Si vous n’avez pas reçu d’e-mail de confirmation, pensez à
          vérifier vos spams.`;
        } else if (success === false) {
          content.title = `Une erreur s'est produite, vous êtes déjà inscrit à notre liste de diffusion`;
        }

        return (
          <div
            style={{
              border: "1px solid #e5eff5",
              boxShadow: "0 2px 3px rgb(3 27 78 / 5%)",
              borderRadius: "var(--radius)",
              padding: "2rem 1.5rem",
              margin: "4rem 0",
              textAlign: "center",
              background: success === true ? customStyles.successBg : customStyles.errorBg
            }}
          >
            <p
              style={{
                fontSize: "1.3rem",
                fontWeight: "bold",
                color: success === true ? customStyles.successColor : customStyles.errorColor
              }}
            >
              {success === true && <FaCheckCircle />}
              {content.title} <br />
              {success === true && <small>{content.message}</small>}
            </p>
          </div>
        );
      }
    };

    return (
      <div className="section bg-grey">
        <Container>
          {renderMessage()}
          <div className="section-title">
            <h1>{this.props.title ? this.props.title : "Rejoignez la communauté"}</h1>
            <div className="underline"></div>
          </div>
          <div
            style={{
              textAlign: "center",
              padding: "1rem"
            }}
          >
            {this.props.dontShow == null && (
              <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                {this.props.message ? this.props.message : "En vous inscrivant à notre lettre de nouvelles"}
                <br />
              </p>
            )}
            {this.props.showImage && (
              <StaticImage
                src="../../assets/images/les-choix-d-un-bon-mari-et-etude-biblique.jpg"
                alt="Étude biblique"
                placeholder="blurred"
                width={300}
              />
            )}

            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <input type="text" name="_gotcha" style={{ display: "none" }} />
                <input
                  type="text"
                  name="name"
                  placeholder="nom"
                  className="form-control"
                  required
                  style={{ background: "#fff", border: "1px solid #ddd" }}
                  value={this.state.formValues.name}
                  onChange={this.handleInputChange}
                />
                <input
                  type="email"
                  name="email"
                  placeholder="email"
                  className="form-control"
                  required
                  style={{ background: "#fff", border: "1px solid #ddd" }}
                  value={this.state.formValues.email}
                  onChange={this.handleInputChange}
                />
                <button type="submit" disabled={this.state.submitting} className={`submit-btn btn`}>
                  S'inscrire
                </button>
              </div>
            </form>
          </div>
        </Container>
      </div>
    );
  }
}
