import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: 0px auto;
  max-width: 700px;
`;

const Wrapper = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Wrapper;
